/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "./../../../../styles/globals.scss" as *;

.inlineSVG {
  &.light {
    rect, line {
      stroke: #fff;
    }
    path {
      fill: #ffffff;
    }
  }
  &.dark {
    rect, line {
      stroke:  $darkBlue;
    }
    path {
      fill: $darkBlue;
    }
  }
  &.default {
    rect, line {
      stroke: $grey--icon;
    }
    path {
      fill: $grey--icon;
    }
  }
  &.grey {
    rect, line {
      stroke: $grey;
    }
    path {
      fill: $grey;
    }
  }
  &.primary {
    rect, line {
      stroke: $primary;
    }
    path {
      fill: $primary;
    }
  }
  &.status--red {
    rect, line {
      stroke: $status--red;
    }
    path {
      fill: $status--red;
    }
  }
  &.status--orange {
    rect, line {
      stroke: $status--orange;
    }
    path {
      fill: $status--orange;
    }
  }
  &.status--green {
    rect, line {
      stroke: $status--green;
    }
    path {
      fill: $status--green;
    }
  }
  &.status--blue {
    rect, line {
      stroke: $status--blue;
    }
    path {
      fill: $status--blue;
    }
  }
}