/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../styles/globals.scss" as *;

.helpDocumentationContainer {
  font-family: $sans;
  color: $darkBlue;
  overflow: hidden;
  display: grid;
  grid-template-columns: 32em 1fr;
  grid-column-gap: 0;
  width: 1600px;
  margin: 0 auto;
  position: relative;
  // padding: 2em;
  // background: #fff;
  // border-radius: $borderRadius--input;
  .siteHeader {
    grid-column: 1 / 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 1em;
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0.5em 1em;
      border-radius: $borderRadius--input;
      transition: all 0.3s ease-in-out;
      color: $primary;
      font-weight: 600;
      background: $primary--10;
      .inlineSVG {
        height: 1.25em;
        path {
          fill: $primary;
        }
      }
      &:hover {
        background: rgba( $primary, 0.3 );
        text-decoration: none;
      }
    }
  }
  .helpMenu {
    padding-right: 1em;
    overflow: auto;
    .collapsibleSectionWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      background: rgba( $darkBlue, 0.05 );
      border-radius: $borderRadius;
      border-bottom: 1px solid transparent;
      transition: all 0.3s ease-in-out;
      margin-bottom: 0.5em;
      padding: 0.75em;
      .collapsibleSectionHeader, .collapsibleSectionBody {
        width: 100%;
      }
      .collapsibleSectionHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 0.5em 1em;
        border-radius: $borderRadius;
        margin-bottom: 0.5em;
        .headerLeft, .headerRight {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .headerLeft {
          align-items: flex-start;
          position: relative;
          font-weight: 600;
          color: $darkBlue;
          text-decoration: none;
        }
        h3 {
          margin: 0;
          padding: 0;
          font-size: 1.125em;
          line-height: 1.125em;
          color: $darkBlue;
          text-decoration: none;
        }
        .headerRight {
          justify-content: flex-end;
          align-items: center;
          color: $darkBlue;
          text-decoration: none;
          strong {
            font-size: 1.125em;
            margin-right: 0.5em;
            color: $darkBlue;
          text-decoration: none;
          }
          .carretWrapper {
            height: 1.5em;
            width: 1.5em;
            background: $grey--divider;
            display: flex;
            align-items: center;
            justify-content: space-around;
            transition: all 0.3s ease-in-out;
            border-radius: $borderRadius--full;
            .inlineSVG {
              width: 0.75em;
              height: 0.75em;
              transition: all 0.3s ease-in-out;
            }
          }
        }
        &:hover {
          background: rgba( $darkBlue, 0.05 );
          color: $darkBlue;
          text-decoration: none;
          .headerLeft {
            text-decoration: none;
            h3 {
              text-decoration: none;
            }
          }
          .headerRight {
            text-decoration: none;
            strong {
              color: $darkBlue;
              text-decoration: none;
            }
            .carretWrapper {
              background: $grey--icon;
            }
          }
        }
      }
      .collapsibleSectionBody {
        overflow: auto;
        position: relative;
        .emptyState {
          font-size: 1em;
        }
      }
      &.menuMain {
        background: #fff;
        border: 1px solid $grey--divider;
        box-shadow: $boxShadow--light;
      }
      &.collapsed {
        background: none;
        border-radius: 0;
        padding: 0.25em 0.75em;
        .collapsibleSectionHeader {
          margin-bottom: 0;
          .headerLeft {
            font-weight: 400;
          }
          .headerRight {
            .carretWrapper {
              background: none;
              transform: rotate( 180deg );
            }
          }
          &:hover {
            .headerRight {
              .carretWrapper {
                background: rgba( $darkBlue, 0.15 );
              }
            }
          }
        }
        .collapsibleSectionBody {
          height: 0;
          overflow: hidden;
        }
        &.menuMain {
          border: 1px solid transparent;
          box-shadow: none;
        }
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
    ul {
      margin-bottom: 0;
      li {
        margin-bottom: 0;
        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
    .menuPageLabel {
      color: $darkBlue;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0.5em 0.75em;
      border-radius: $borderRadius;
      height: unset;
      position: relative;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: rgba( $darkBlue, 0.15 );
      }
      &.isSelected {
        padding-left: 2em;
        background: rgba( $darkBlue, 0.15 );
        font-weight: 600;
        &:before {
          content: '';
          position: absolute;
          top: calc( 50% - ( 0.75em / 2 ) );
          left: 0.75em;
          width: 0.75em;
          height: 0.75em;
          background: $primaryBlue;
          border-radius: $borderRadius--full;
        }
      }
    }
  }
  .breadcrumbsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .breadcrumbLink {
      color: $primary;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &.current {
        cursor: default;
        color: $grey;
        &:hover {
          text-decoration: none;
        }
      }
    }
    span {
      display: block;
      margin: 0 0.375em;
    }
  }
  .helpDocumentationWrapper {
    height: calc( 100vh - 6em - 3em );
    padding: 2em;
    color: $darkBlue;
    background: #fff;
    position: relative;
    border-radius: $borderRadius--input;
    .helpDocumentationHeader {
      position: absolute;
      top: 2em;
      left: 2em;
      width: calc( 100% - 4em );
      background: #fff;
      height: 6em;
      border-bottom: 1px solid $grey--divider;
      h1 {
        font-size: 2em;
        line-height: 2em;
        margin: 0;
        padding: 0;
        font-weight: 500;
      }
    }
    .helpDocumentationFooter {
      position: absolute;
      bottom: 0;
      width: calc( 100% - 4em );
      left: 2em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      background: #fff;
      height: 5.5em;
      border-top: 1px solid $grey--divider;
      a, button {
        @include dsButton( 'minimal-dark' );
        line-height: 1.5em;
        background: none;
        span {
          font-weight: 400;
        }
        .inlineSVG {
          margin-left: 0.5em;
          path {
            fill: $darkBlue;
          }
        }
        &.previous {
          @include dsButton( 'minimal-dark' );
          background: none;
          span {
            font-weight: 400;
          }
          .inlineSVG {
            margin-left: 0;
            margin-right: 0.5em;
          }
        }
        &.disabled {
          @include dsButton( 'minimal' );
          color: $darkBlue !important;
          span {
            font-weight: 400;
          }
        }
      }
    }
    .helpContentWrapper {
      height: calc( 100vh - 6em - 11.5em - 5em );
      overflow: auto;
      padding-top: 2em;
      padding-bottom: 2em;
      margin-top: 6em;
      margin-bottom: 6.5em;
      p, img {
        max-width: 100%;
      }
      // bit of a reset
      p, pre, ul, li, ol, code {
        font-size: 1em;
        line-height: 2em;
        margin-bottom: 0;
      }
      ul {
        list-style: disc;
        margin-left: 1.5em;
        li {
          list-style: disc;
          margin-bottom: 0;
        }
      }
      ol {
        margin-left: 1.5em;
        padding-left: 0;
        li {
          margin-bottom: 0;
        }
      }
      p, ul, ol {
        margin-bottom: 1em;
      }
      code {
        background: $grey--divider;
        padding: 0.25em 0.625em;
        font-family: $fixed;
        font-weight: 600;
        border-radius: $borderRadius;
        border: none;
        font-size: 0.875em;
      }
      pre {
        code {
          background: none;
          padding: 0;
          border-radius: unset;
        }
      }
      img {
        max-width: 80%;
        margin: 0 auto;
        display: block;
        border-radius: $borderRadius;
        &.half {
          max-width: 45%;
        }
        &.third {
          max-width: 30%;
        }
        &.quarter {
          max-width: 20%;
        }
      }
      table {
        border: 1px solid $grey--divider;
        border-collapse: collapse;
        margin-bottom: 1em;
        thead {
          tr {
            th {
              padding: 0.5em 1em;
            }
          }
        }
        tbody {
          tr {
            &:nth-child(odd) {
              background: $grey--divider;
            }
            td {
              padding: 0.5em 1em;
            }
          }
        }
      }
      .markdownContent.pageContent {
        &.changelogs {
          h1 {
            font-weight: 600;
          }
          em {
            font-style: normal;
            color: $grey;
          }
          h2 {
            font-size: 1em;
            margin-bottom: 0.5em;
          }
          ul {
            margin-bottom: 2em;
            margin-right: 1em;
            &:last-of-type {
              padding-bottom: 2em;
              border-bottom: 1px solid $grey--divider;
            }
          }
          &:last-of-type {
            ul {
              &:last-of-type {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
              }
            }
          }
        }
        &.licenses {
          pre {
            margin-bottom: 2em;
            margin-right: 2em;
          }
        }
      }
      &.page--documentation_index,

      &.page--user_guide_main_index,
      &.page--security_guide_main_index,
      &.page--integrations_guide_main_index,
      &.page--installation_guide_main_index,

      &.page--deployment_tools_section_index,
      &.page--risk_insight_section_index,
      &.page--reporting_section_index,
      &.page--remediation_section_index,
      &.page--remediation_integrations_section_index,
      &.page--explore_section_index,
      &.page--activity_section_index,
      &.page--scanning_section_index,
      &.page--setup_section_index,
      &.page--providers_section_index,
      &.page--pam_section_index,
      &.page--vulnerability_scanners_section_index,
      &.page--virtual_machines_section_index,
      &.page--deployment_options_section_index,
      &.page--miscellaneous_section_index,
      &.page--firewall_config_section_index,
      &.page--how_scans_work_section_index,
      &.page--other_security_items_section_index {
        .pageContent {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-gap: 1em;
          h3, h2, h1, h4, p, .sectionIndex {
            grid-column: 1 / 5;
          }
          img {
            width: 40em;
            display: block;
            margin: 0 auto 2em auto;
          }
          .indexLinkWrapper {
            border-radius: $borderRadius--input;
            border: 1px solid $grey--divider;
            box-shadow: $boxShadow--light;
            padding: 1em;
            transition: all 0.2s ease-in-out;
            a {
              color: $darkBlue;
              font-weight: 400;
              text-decoration: none;
              display: block;
              transition: all 0.2s ease-in-out;
              font-size: 1.125em;
              &:hover {
                color: $primary;
              }
            }
            .sectionLink {
              font-size: 1.25em;
              padding-bottom: 0.5em;
              border-bottom: 1px solid $grey--divider;
              margin-bottom: 0.5em;
              font-weight: 600;
            }
            .pagesHeader {
              font-size: 1;
              padding-bottom: 0.5em;
              border-bottom: 1px solid $grey--divider;
              margin-bottom: 0.5em;
              font-weight: 600;
              display: block;
              width: 100%;
            }
            ul {
              margin: 0;
              padding: 0;
              li {
                margin: 0;
                padding: 0;
                margin-bottom: 0.25em;
                list-style: none;
              }
            }
            &:hover {
              box-shadow: $boxShadow--medium;
            }
          }
          .mainPageLink {
            border-radius: $borderRadius--input;
            border: 1px solid $grey--divider;
            box-shadow: $boxShadow--light;
            padding: 1em;
            transition: all 0.2s ease-in-out;
            display: block;
            color: $darkBlue;
            text-decoration: none;
            font-weight: 500;
            text-align: center;
            &:hover {
              color: $primary;
              background: $primary--10;
              box-shadow: $boxShadow--medium;
              border: 1px solid $primary;
            }
          }
        }
      }
    }
    .multipleImageWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1em;
      padding: 1em;
      border-radius: $borderRadius;
      background: $grey--background;
      align-items: center;
      img {
        width: 100%;
        max-width: 100%;
        box-shadow: $boxShadow--light;
      }
    }
    .imageAndContentWrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 1em;
      background: $grey--background;
      padding: 0.5em 0.5em 0.5em 1em;
      border-radius: $borderRadius;
      img {
        width: 32em;
        margin-left: 1em;
        margin-right: 0;
        order: 2;
      }
      .content {
        order: 1;
        flex-grow: 1;
      }
      &.imageLeft {
        margin-left: 0;
        margin-right: 1em;
        padding: 0.5em 1em 0.5em 0.5em;
        img {
          order: 1;
        }
        .content {
          order: 2;
        }
      }
    }
  }
}
