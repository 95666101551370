/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@import "/documentation/share/webroot/google-fonts/Open-Sans.css";

/***************************************************************
* fonts *
***************************************************************/
$sans: "Open Sans";
$fixed: "Courier New", monospace;

/***************************************************************
* colors *
***************************************************************/

// primary colors
$primaryBlue: #00AAE9;
$primary: $primaryBlue;
$darkBlue: #334D6E;
$primary--10: #e6f7fd;

// greys
$grey: #90A0B7;
$grey--icon: #C2CFE0;
$grey--divider: #EBEFF2;
$grey--background: #F7F7FA;
$grey--background--light: #FCFCFD;

// status colors
$status--blue: #2D9CDB;
$status--green: #6FCF97;
$status--yellow: #F9CD49;
$status--orange: #F2994A;
$status--red: #F7685B;

$status--green--10: #f1fbf5;
$status--red--10: #fff0ef;
$status--blue--10: #eaf6fc;
$status--yellow--10: #fffaed;
$status--orange--10: #fef5ed;

// size, spacing, etc.
$borderRadius: 0.375em;
$borderRadius--input: 0.5em;
$borderRadius--full: 9999em;

$boxShadow--button: 3px 3px 6px 0px rgba(#000000, .2);

$boxShadow: 0px 0px 4px 0px rgba(#000000, .16);
$boxShadow--input: 1px 1px 2px 1px rgb(#000000, .05);
$boxShadow--light: 1px 2px 8px 1px rgb(#000000, .05);
$boxShadow--light--reverse: -4px 2px 8px 1px rgb(#000000, .05);
$boxShadow--medium: 0px 4px 16px 0px rgba(#000000, .12);
$boxShadow--heavy: 0px 8px 32px 0px rgba(#000000, .12);
$boxShadow--veryHeavy: 0px 8px 32px 0px rgba(#000000, .48);

$boxShadow--inset: 0px 4px 16px 0px rgba(#000000, .12) inset;

// mixins
@mixin dsButton($type: 'none') {
  line-height: 1.5em;
  display: flex;
  color: #fff;
  text-align: center;
  padding: .375em 1em;
  border-radius: $borderRadius--input;
  margin: 0;
  border: none;
  cursor: pointer;
  // transition: all 0.3s ease-in-out;
  font-weight: 600;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
  .inlineSVG {
    height: 1.5em;
    width: 1.5em;
    margin-right: 0.25em;
  }
  span {
    line-height: 1.5em;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
  @if $type == minimal {
    background: none;
    border: none;
    color: $darkBlue;
    padding: 0.375em 1em;
    opacity: 0.7;
    line-height: 1.5em;
    &:hover {
      opacity: 1;
      background: $grey--divider;
      box-shadow: none;
      color: $darkBlue;
    }
    &:focus {
      opacity: 1;
      background: $grey--divider;
      box-shadow: none;
      color: $darkBlue;
    }
    &:disabled, &.disabled {
      background: none;
      opacity: 0.2;
      cursor: default;
      pointer-events: none;
      color: $grey;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == minimal-primary {
    color: $primary;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: $primary--10;
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    line-height: 1.5em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: rgba( $primary, 0.3 );
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: $primary--10;
      cursor: default;
      pointer-events: none;
      color: $primary;
      opacity: 0.4;
      &:hover {
        background: $primary--10;
        box-shadow: none;
      }
    }
  } @else if $type == minimal-default {
    line-height: 1.5em;
    color: $grey;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: $grey--divider;
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
      path {
        fill: $grey;
      }
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: rgba($grey, 0.3);
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: $grey--divider;
      cursor: default;
      pointer-events: none;
      color: #fff;
      &.next {
        margin-left: 1em !important;
      }
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == minimal-dark {
    color: $darkBlue;
    line-height: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: rgba( $darkBlue, 0.1 );
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: rgba( $darkBlue, 0.25 );
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: $grey--divider;
      cursor: default;
      pointer-events: none;
      color: #fff;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == none {
    background: #fff;
    color: $darkBlue;
    &:hover {
      box-shadow: 0px 0px 0px 3px rgba($primary, 0.4);
    }
  } @else if $type == disabled {
    background: $primary;
    opacity: 0.15;
    cursor: default;
    pointer-events: none;
    color: #fff;
    &:hover {
      box-shadow: none;
    }
  }
}